import {apiCall, paramsToString} from "utils/api";
import {userId} from "utils/auth";
import {NextPageContext} from "next";
import {isShopTabDomain} from "../utils/misc";

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/users`;

export const getShippingAddresses = async (limit: number, offset: number, searchTerm: string, userId: number, ctx: NextPageContext) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/${userId}/shippingAddresses?${paramsToString({
      limit,
      offset,
      searchTerm,
    })}`,
    ctx
  })
  return {props: {shippingAddresses: res.data}};
}

export const verifyOtp = async (mobileNumber: string, otp: string, authToken: string) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/registration`,
    data: {userPhone: mobileNumber},
    headers: {
      'Otp': otp,
      'X-Auth-Token': authToken,
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
  })
  return {userDetails: res.data};
}

export const getPurchaseHistory = async (orderId?: number) => {
  const params:{orderId?: number} = {};
  if (orderId) {
    params['orderId'] = orderId;
  }
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/${userId()}/clout/purchaseHistory?${paramsToString(params)}`,
    headers: {
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    }
  })
  return {purchaseHistory: res.data};
}

export const saveBankDetails = async (bankDetails: any, suborderId: number) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/${userId()}/bankDetails${isShopTabDomain() ? `?suborderId=${suborderId}` : ''}`,
    headers: {
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
    data: bankDetails,
  })
  return {bankDetails: res.data};
}
